import React, { useState } from "react";
import Swal from 'sweetalert2';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import '../index.css'


import { ScrollPanel } from 'primereact/scrollpanel';

async function getMe() {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/me`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getShop(shop_id) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

export default function HomeView() {
    const [position, setPosition] = useState("");
    const [dialogAct, setDialogAct] = useState(false);
    const [dialogTricket, setDialogTricket] = useState();
    const [isMobile, setIsMobile] = useState(false);

    useState(async () => {

        if (window.innerWidth <= 480) {
            setIsMobile(true);

        } else {
            setIsMobile(false);
        }
        const response = await getMe();

        if (response.logout === true) {
            localStorage.clear();
            window.location.href = "/";
        }

        if (response.level === 'member') {
            setPosition('One Stop Platform')
        } else if (response.level === 'employee') {
            const shop = await getShop(response.data.employee_shop_id);
            if (!shop) {
                Swal.fire({
                    title: "ออกจากระบบ?",
                    text: 'ไม่พบร้านค้าที่ท่านสังกัด กรุณาออกจากระบบ',
                    icon: "warn",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    // cancelButtonColor: "#d33",
                    confirmButtonText: "ยืนยัน",
                    // cancelButtonText: "ยกเลิก",
                }).then((result) => {
                    if (result.isConfirmed) {
                        localStorage.clear();
                        window.location.href = "/";
                    }
                })
            }
            if (shop.data.shop_status === false) {
                Swal.fire({
                    title: 'แจ้งเตือน!',
                    text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 1500,
                });
                return false;
            }
            setPosition(shop.data.shop_type)
        }
    })

    const handleClick = async e => {
        e.preventDefault();
        Swal.fire({
            title: 'ขออภัย',
            icon: 'info',
            text: `บริการดังกล่าวยังไม่เปิดให้ใช้บริการ`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const headleClickSabuy = async e => {
        e.preventDefault();
        Swal.fire({
            title: "แจ้งเตือน!",
            icon: "info",
            text: `บริการดังกล่าวยังอยู่ในช่วงพัฒนาระบบ จึงจำเป็นต้องใช้ระบบภายนอกเป็นการชั่วคราว ต้องการต้องใช้งานกรุณากด "ยืนยัน"`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
        }).then((result) => {
            if (result.isConfirmed) {
                // localStorage.clear();
                window.location.href = 'https://sabuycounter.sabuytech.com/login';
            }
        })
    };

    const headleClickEmarket = async e => {
        const token = JSON.parse(localStorage.getItem('token'));
        console.log(token)
        // window.location.href = `http://localhost:3004/?token=${token}`;
        window.location.href = `https://e-market.tossaguns.online/?token=${token}`;
        // window.location.href = `https://e-market.tossaguns.com/?token=${token}`;
    };

    const heandleAct = () => {
        setDialogAct(true);
    };

    const onHideDialogAct = () => {
        setDialogAct(false);
    };

    const headleTricket = () => {
        setDialogTricket(true);
    };

    const onHideDialogTrciket = () => {
        setDialogTricket(false);
    };



    return (
        <div className="home-container">
            <div>
                <img alt="" src={require('../assets/slid/announce1.jpg')} className="home-img" />
            </div>
            <div>
                <ScrollPanel className="home-scroll">
                    <div className="home-title-menu">
                        <h1>บริการหลัก</h1>
                    </div>
                    <Grid container >
                        {/* ใช้งานได้แล้ว */}
                        {position === 'One Stop Shop' && (
                            <Grid item className="home-menu" >
                                <Link href="/pos">
                                    <img src={require('../assets/menu/pos.png')} alt="img" className={`home-menu-img  ${isMobile ? 'sm' : ''}`} />
                                </Link>
                            </Grid>
                        )}
                        {/* ใช้งานได้แล้ว */}
                        {position !== 'One Stop Platform' && (
                            <Grid item className="home-menu">
                                <Link
                                    href="/express"
                                >
                                    <img src={require('../assets/menu/express.png')} alt="img" className={`home-menu-img  ${isMobile ? 'sm' : ''}`} />
                                </Link>
                            </Grid>
                        )}
                        {/* กำลังดำเนินการ */}
                        {position === 'One Stop Platform' && (
                            <Grid item className="home-menu">
                                <Link
                                    onClick={headleClickEmarket}
                                >
                                    <img src={require('../assets/menu/e-market.png')} alt="img" className={`home-menu-img  ${isMobile ? 'sm' : ''}`} />
                                </Link>
                            </Grid>
                        )}
                        {/* ใช้งานได้แล้ว */}
                        <Grid item className="home-menu">
                            <Link
                                onClick={headleClickSabuy}
                            >
                                <img src={require('../assets/menu/counterservice.png')} alt="img" className={`home-menu-img  ${isMobile ? 'sm' : ''}`} />
                            </Link>
                        </Grid>
                        {/* ใช้งานได้แล้ว */}
                        <Grid item className="home-menu">
                            <Link
                                // onClick={handleClick}
                                href="/temtopup"
                            >
                                <img src={require('../assets/menu/temwallet.png')} alt="img" className={`home-menu-img  ${isMobile ? 'sm' : ''}`} />
                            </Link>
                        </Grid>
                        {/* ใช้งานได้แล้ว */}
                        <Grid item className="home-menu">
                            <Link
                                onClick={headleClickSabuy}
                            >
                                <img src={require('../assets/menu/bankagent.png')} alt="img" className={`home-menu-img  ${isMobile ? 'sm' : ''}`} />
                            </Link>
                        </Grid>
                        {/* กำลังดำเนินการ */}
                        <Grid item className="home-menu">
                            <Link
                            // onClick={handleClick}
                            >
                                {/* <img src={require('../assets/menu/photo.png')} alt="img" className={'home-menu-img'} /> */}
                                <img src={require('../assets/comming/16_0.png')} alt="img" className={`home-menu-img  ${isMobile ? 'sm' : ''}`} />
                            </Link>
                        </Grid>
                        {/* กำลังดำเนินการ */}
                        <Grid item className="home-menu">
                            <Link
                                href="/tricket"
                            // onClick={headleTricket}
                            >
                                <img src={require('../assets/comming/20_0.png')} alt="img" className={`home-menu-img  ${isMobile ? 'sm' : ''}`} />
                                {/* <img src={require('../assets/menu/trickair.png')} alt="img" className='home-menu-img' /> */}
                            </Link>
                        </Grid>
                        {/* กำลังดำเนินการ */}
                        <Grid item className="home-menu">
                            <Link
                                href="/act"
                            // onClick={heandleAct}
                            >
                                <img src={require('../assets/menu/car_act.png')} alt="img" className={`home-menu-img  ${isMobile ? 'sm' : ''}`} />
                                {/* <img src={require('../assets/comming/8_0.png')} alt="img" className='home-menu-img' /> */}
                            </Link>
                        </Grid>
                        {/* กำลังดำเนินการ */}
                        <Grid item className="home-menu">
                            <Link
                                href="/tax"
                            // onClick={heandleAct}
                            >
                                <img src={require('../assets/menu/car_tax.png')} alt="img" className={`home-menu-img  ${isMobile ? 'sm' : ''}`} />
                                {/* <img src={require('../assets/comming/8_0.png')} alt="img" className='home-menu-img' /> */}
                            </Link>
                        </Grid>
                        {/* กำลังดำเนินการ */}
                        <Grid item className="home-menu">
                            <Link
                            // onClick={handleClick}
                            >
                                {/* <img src={require('../assets/menu/lotto.png')} alt="img" className={'home-menu-img'} /> */}
                                <img src={require('../assets/comming/3_0.png')} alt="img" className={`home-menu-img  ${isMobile ? 'sm' : ''}`} />
                            </Link>
                        </Grid>
                        {/* ใช้งานได้แล้ว */}
                        <Grid item className="home-menu">
                            <Link
                                href="/artwork"
                            >
                                <img src={require('../assets/menu/artwork.png')} alt="img" className={`home-menu-img  ${isMobile ? 'sm' : ''}`} />
                            </Link>
                        </Grid>
                        {/* ใช้งานได้แล้ว */}
                        <Grid item className="home-menu">
                            <Link
                                href="/rubber"
                            >
                                <img src={require('../assets/menu/stamp.png')} alt="img" className={`home-menu-img  ${isMobile ? 'sm' : ''}`} />
                            </Link>
                        </Grid>
                        {/* กำลังดำเนินการ */}
                        <Grid item className="home-menu">
                            <Link
                            // onClick={handleClick}
                            >
                                {/* <img src={require('../assets/menu/other.png')} alt="img" className={'home-menu-img'} /> */}
                                <img src={require('../assets/comming/10_0.png')} alt="img" className={`home-menu-img  ${isMobile ? 'sm' : ''}`} />
                            </Link>
                        </Grid>

                    </Grid>


                    <div className="home-title-menu">
                        <h1>บริการอื่นๆ</h1>
                    </div>
                    <Grid container className="home-model">
                        <Grid item className="home-menu">
                            <Link
                            // onClick={handleClick}
                            >
                                {/* <img src={require('../assets/sub_menu/hotel.png')} alt="img" className={'home-menu-img'} /> */}
                                <img src={require('../assets/comming/22_0.png')} alt="img" className='home-menu-img' />
                            </Link>
                        </Grid>
                        <Grid item className="home-menu">
                            <Link
                            // onClick={handleClick}
                            >
                                {/* <img src={require('../assets/sub_menu/packageinternet.png')} alt="img" className={'home-menu-img'} /> */}
                                <img src={require('../assets/comming/23_0.png')} alt="img" className='home-menu-img' />
                            </Link>
                        </Grid>
                        <Grid item className="home-menu">
                            <Link
                            // onClick={handleClick}
                            >
                                {/* <img src={require('../assets/sub_menu/installinternet.png')} alt="img" className={'home-menu-img'} /> */}
                                <img src={require('../assets/comming/17_0.png')} alt="img" className='home-menu-img' />
                            </Link>
                        </Grid>
                        <Grid item className="home-menu">
                            <Link
                                // onClick={handleClick}
                                href="/apppremium"
                            >
                                <img src={require('../assets/sub_menu/apppremium.png')} alt="img" className={'home-menu-img'} />
                                {/* <img src={require('../assets/comming/13_0.png')} alt="img" className='home-menu-img' /> */}
                            </Link>
                        </Grid>
                        <Grid item className="home-menu">
                            <Link
                            // onClick={handleClick}
                            >
                                {/* <img src={require('../assets/sub_menu/telluckky.png')} alt="img" className={'home-menu-img'} /> */}
                                <img src={require('../assets/comming/21_0.png')} alt="img" className='home-menu-img' />
                            </Link>
                        </Grid>
                        <Grid item className="home-menu">
                            <Link
                            // onClick={handleClick}
                            >
                                {/* <img src={require('../assets/sub_menu/gold.png')} alt="img" className={'home-menu-img'} /> */}
                                <img src={require('../assets/comming/5_0.png')} alt="img" className='home-menu-img' />
                            </Link>
                        </Grid>
                    </Grid>
                    <div className="home-title-menu">
                        <h1>บริการทางด้านวิชาชีพ</h1>
                    </div>
                    <Grid container className="home-model">
                        <Grid item className="home-menu">
                            <Link
                            // href="/account"
                            // onClick={handleClick}
                            >
                                {/* <img src={require('../assets/per_menu/account.png')} alt="img" className={'home-menu-img'} /> */}
                                <img src={require('../assets/comming/11_0.png')} alt="img" className='home-menu-img' />
                            </Link>
                        </Grid>
                        <Grid item className="home-menu">
                            <Link
                            // href="/website"
                            // onClick={handleClick}
                            >
                                <img src={require('../assets/per_menu/website.png')} alt="img" className='home-menu-img' />
                            </Link>
                        </Grid>
                        <Grid item className="home-menu">
                            <Link
                            // onClick={handleClick}
                            >
                                {/* <img src={require('../assets/per_menu/computer.png')} alt="img" className={'home-menu-img'} /> */}
                                <img src={require('../assets/comming/19_0.png')} alt="img" className='home-menu-img' />
                            </Link>
                        </Grid>
                        <Grid item className="home-menu">
                            <Link
                            // onClick={handleClick}
                            >
                                {/* <img src={require('../assets/per_menu/architect.png')} alt="img" className={'home-menu-img'} /> */}
                                <img src={require('../assets/comming/2_0.png')} alt="img" className='home-menu-img' />
                            </Link>
                        </Grid>
                        <Grid item className="home-menu">
                            <Link
                            // onClick={handleClick}
                            >
                                {/* <img src={require('../assets/per_menu/itsubport.png')} alt="img" className={'home-menu-img'} /> */}
                                <img src={require('../assets/comming/15_0.png')} alt="img" className='home-menu-img' />
                            </Link>
                        </Grid>
                        <Grid item className="home-menu">
                            <Link
                            // href="/marketing"
                            // onClick={handleClick}
                            >
                                {/* <img src={require('../assets/per_menu/marketing.png')} alt="img" className={'home-menu-img'} /> */}
                                <img src={require('../assets/comming/12_0.png')} alt="img" className='home-menu-img' />
                            </Link>
                        </Grid>
                    </Grid>
                </ScrollPanel>
            </div>
        </div>
    )
};